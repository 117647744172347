// PDTTable.js

import React from 'react';
import './Prices.css'; // Import your CSS file for styling

const Prices = () => {
    const daysAndPrices = [
        { price: 'NÍVEL 1 - 10,000MT', days: '3X[5H-14H]' },
        { price: 'NÍVEL 1 - 12,500MT', days: '5X[5H-14H]' },
        { price: 'NÍVEL 1 - 12,500MT', days: '5X[5H-21H]' },
        { price: 'NÍVEL 1 - 15,000MT', days: '5X[5H-21H]' },
        { price: 'PACOTE +60 - 6,800MT', days: '3X[9H-15H]' },
        { price: 'PACOTE +60 - 9,300MT', days: '5X[9H-15H]' },
        { price: 'PACOTE +60 - 9,900MT', days: '5X[9H-15H]' },
        { price: 'PACOTE +60 - 12,400MT', days: '5X[5H-21H]' },
        { price: 'P. ESTUDANTE - 6,800MT', days: '3X[9H-15H]' },
        { price: 'P. ESTUDANTE - 9,300MT', days: '5X[9H-15H]' },
        { price: 'P. ESTUDANTE - 9,900MT', days: '5X[5H-21H]' },
        { price: 'P. ESTUDANTE - 12,400MT', days: '5X[5H-21H]' }
        
    ];

    return (
        <section className="pdt-table-section">
             <div className="programs-header" style={{ gap: "2rem" }}>
            <table className="pdt-table">
           
                <thead>
                    <tr>
                        <th><span className="stroke-text">Day of the Week</span></th>
                        <th><span>Price</span></th>
                    </tr>
                </thead>
            
                <tbody>
                    {daysAndPrices.map((item, index) => (
                        <tr key={index}>
                            <td>{item.price}</td>
                            <td>{item.days}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </section>
    );
};

export default Prices;
